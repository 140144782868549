<template>
	<div class="panel" :style="{ minWidth: '850px' }">
		<el-button type="primary">主要按钮</el-button>
	</div>
</template>

<script>
import { mapGetters, mapState, mapMutations } from 'vuex';

export default {
	name: 'panel',
	components: {},
	data () {
		return {

		};
	},
	props: {},
	created () {

	},
	mounted () {

	},
	computed: {
		// ...mapGetters(['permission_routes', 'userInfo', 'dutyTime', 'roleId', 'unDuty'])
	},
	methods: {
		// ...mapMutations(['SET_REMINDNUM']),

	},
	beforeDestroy () {

	},
	watch: {
		// $route: {
		// 	handler (val, oldval) {
		// 		// console.log(val, oldval);
		// 		// if (val.meta.pPath && val.meta.pPath !== '/') {
		// 		// 	this.menuSelect(val.meta.pPath);
		// 		// } else {
		// 		// 	this.menuSelect(val.path);
		// 		// }
		// 	},
		// 	deep: true
		// }
	}
};
</script>
<style scoped lang="scss">
</style>
