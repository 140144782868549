var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "template", style: { minWidth: "850px" } },
    [_c("el-button", { attrs: { type: "primary" } }, [_vm._v("主要按钮")])],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }