<template>
  <div class="asideList">
    <div class="head">
      <div class="search">
        <!-- @input="searchTree" -->
        <el-input
          class="search-w176 wp-input"
          v-model="searchContent"
          clearable
          placeholder="请输入队伍名称"
        >
          <i
            slot="prefix"
            class="wp-input-icon icon-sousuo"
            style="font-size: 20px"
          ></i>
        </el-input>
      </div>

      <div
        class="complete flex-CB wp-pointer"
        :class="{ active_item: !checkId }"
        @click="getAll"
      >
        <div class="fs-16 fw-bold wp-pointer">全部</div>
      </div>
    </div>
    <div
      class="list-box"
      v-loading="loading"
      element-loading-spinner="el-icon-loading"
      element-loading-text="正在加载"
    >
      <el-scrollbar style="height: 100%">
        <div class="list">
          <el-tree
            :filter-node-method="filterNode"
            :data="treeData"
            node-key="groupId"
            :props="defaultProps"
            ref="treeNodeKey"
            :default-expanded-keys="defaultExpandedKeys"
            :expand-on-click-node="false"
            @node-click="nodeClick"
            @node-expand="nodeExpand"
            @node-collapse="nodeCollapse"
          >
            <span class="custom-tree-node" slot-scope="{ node, data }">
              <el-tooltip
                class="item"
                effect="dark"
                :content="node.label"
                placement="top"
                :disabled="!isShowTooltipWidth"
                :tabindex="node.id"
              >
                <div
                  class="node-labe textOverOneLine"
                  @mouseenter="visibilityChangeWidth($event)"
                >
                  {{ node.label }}
                </div>
              </el-tooltip>
              <div v-if="treeData.length > 1">
                <el-dropdown
                  placement="bottom-end"
                  trigger="click"
                  class="wp-dropdown"
                  @command="handlerItem"
                >
                  <div class="icon">
                    <i
                      v-if="(data.isShiftDown || data.isShiftUp) && data.operate != 1"
                      class="el-input_icon el-icon-more"
                    ></i>
                  </div>

                  <el-dropdown-menu
                    slot="dropdown"
                    :visibleArrow="false"
                    class="wp-dropdown-menu"
                  >
                    <el-dropdown-item
                      :command="['shiftUp', data]"
                      icon="icon-shangyi"
                      v-if="data.isShiftUp && data.operate != 1"
                      >上移
                    </el-dropdown-item>
                    <el-dropdown-item
                      :command="['shiftDown', data]"
                      icon="icon-xiayi"
                      v-if="data.isShiftDown && data.operate != 1"
                      >下移
                    </el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
              </div>
            </span>
          </el-tree>
        </div>
      </el-scrollbar>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    asideList: {
      type: Array,
      default: () => []
    },
    loading: {
      type: Boolean,
      default: false
    },
    // 湘江新区账号
    xjxqFlag: {
      type: Boolean,
      default: false
    }
  },
  data () {

    return {
      checkId: '',
      defaultProps: {
        children: 'sysGroupList',
        label: 'groupName'
      },
      searchContent: "",
      activeId: "",
      isShowTooltipWidth: false,
      defaultExpandedKeys: []
    }
  },
  computed: {
    treeData () {
      console.log(JSON.parse(JSON.stringify(this.asideList)));
      return JSON.parse(JSON.stringify(this.asideList))
    }
  },
  mounted () {
    console.log(this.treeData)
    // this.data = JSON.parse(JSON.stringify(this.asideList))
    this.setCurrentKey()

  },
  methods: {
    filterNode (value, data) {
      if (!value) return true;
      console.log(data)
      return data.groupName.indexOf(value) !== -1;
    },
    setCheck (flag) {
      console.log(this.checkId)
      // 判断这个id 还在不在树中 不在树中 选中全部
      let checkId = flag ? this.checkId : null
      this.$refs.treeNodeKey.setCurrentKey(checkId)
      if (!flag && this.checkId) { this.getAll() }
    },
    // 搜索树
    searchTree () {
      this.$emit("searchTree", this.searchContent)
    },
    // 节点点击事件
    nodeClick (data, node) {
      console.log(data, node)
      // console.log(node)
      this.checkId = data.groupId
      this.$emit("nodeClick", data)
    },
    // 点击侧边栏单项数据
    handlerAsideItem (item) {
      this.activeId = item.id
      this.$emit("handlerAsideItem", item)
    },
    setCurrentKey () {
      this.$refs.treeNodeKey.setCurrentKey(2)
    },
    // 操作单项数据
    handlerItem (item) {
      console.log(item)
      const obj = item[1]
      obj.type = item[0]
      console.log(obj)
      this.$emit("handlerItem", obj)
    },
    addGroup () {
      this.$emit("addGroup")
    },
    // 切换到全部
    getAll () {
      this.$refs.treeNodeKey.setCurrentKey(null)
      // 将树的选中项 都清空
      // 将选中的节点置空并 将结果返回给父组件 去获取新的用户列表
      this.nodeClick({ groupId: '' })
    },
    // hover 是否显示全文
    visibilityChangeWidth (event) {
      this.isShowTooltipWidth = this.$utils.visibilityChange(event)
    },
    nodeExpand (data) {
      console.log(data)
      if (this.defaultExpandedKeys.indexOf(data.groupId) == -1) {
        this.defaultExpandedKeys.push(data.groupId)
      }
    },
    nodeCollapse (data) {
      console.log(data)
      if (this.defaultExpandedKeys.indexOf(data.groupId) != -1) {
        this.defaultExpandedKeys.splice(this.defaultExpandedKeys.indexOf(data.groupId), 1)
      }
    }
  },
  watch: {
    // asideList: {
    //   handler (newVal, oldVal) {
    //     this.activeId = newVal?.[0]?.id
    //   },
    //   immediate: true,
    //   deep: true
    // },
    searchContent (val) {
      this.$refs.treeNodeKey.filter(val);
    }
  }
}
</script>

<style lang="scss" scoped>
@import './index.scss';
</style>