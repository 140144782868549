<template>
  <div style="width: 100%">
    <el-tabs class="tabs" v-model="active" @tab-click="handleClick">
      <el-tab-pane
        v-for="item in unitList"
        :key="item.custId"
        :label="item.label"
        :name="item.custId"
      ></el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
export default {
  props: {
    unitList: {
      type: Array,
      required: true
    },
    activeName: {
      type: [String, Number],
    }
  },
  data () {
    return {
      active: this.activeName
    }
  },
  created () {
    console.log(this.activeName);
  },
  methods: {
    handleClick () {
      this.$emit("handleTabs", this.active)
    }
  },
}
</script>

<style lang="scss" scoped>
@import './index.scss';
</style>